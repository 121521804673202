import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "@emotion/styled";
import { StyledLink } from "../../styles/typography";
import FollowUs from "./follow-us";

const MenuContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background: rgb(58, 70, 72);

  @media screen and (min-width: 768px) {
    padding: 200px 100px;
    background: linear-gradient(
      133deg,
      rgba(58, 70, 72, 1) 80%,
      rgba(221, 97, 74, 1) 100%
    );
  }
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 100%;
  margin-top: 120px;

  @media screen and (min-width: 768px) {
    margin-top: 0;
    grid-template-columns: 50% 50%;
  }
`;

const Links = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: 768px) {
    align-items: flex-start;
  }
`;

const PageLink = styled(StyledLink)`
  background: transparent;
  font-size: 30px;
  text-transform: none;
  margin-bottom: 30px;
  transition: color 0.5s ease-in-out;

  &:hover {
    background: transparent;
    color: #dd614a;
    box-shadow: none;
  }

  @media screen and (min-width: 768px) {
    font-size: 40px;
  }
`;

const LogoImage = styled(GatsbyImage)`
  display: none;
  width: 100%;

  @media screen and (min-width: 768px) {
    display: block;
  }
`;

const StyledFollowUsContainer = styled.div`
  @media screen and (min-width: 768px) {
    margin-right: 80px;
  }
`;

const activeStyle = { color: "#DD614A" };

const linksList = [
  {
    name: "Home",
    url: "/",
  },
  {
    name: "História",
    url: "/historia/",
  },
  {
    name: "Produtos",
    url: "/produtos/",
  },
  {
    name: "Encomendas",
    url: "/encomendas/",
  },
  {
    name: "Receitas",
    url: "/receitas/",
  },
  {
    name: "Contactos",
    url: "/contactos/",
  },
];

// eslint-disable-next-line consistent-return
const whatLocation = (location, link) => {
  const currentLocation = location.pathname;

  if (currentLocation === link.url) {
    return { style: activeStyle };
  }

  if (link.name !== "Home" && currentLocation.includes(link.url)) {
    return { style: activeStyle };
  }
};

const Menu = () => {
  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "logotipo.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
    }
  `);

  return (
    <MenuContainer>
      <Container>
        <Links>
          {linksList.map((link) => (
            <div key={link.name}>
              <PageLink
                to={link.url}
                activeStyle={activeStyle}
                getProps={({ location }) => whatLocation(location, link)}
              >
                {link.name}
              </PageLink>
            </div>
          ))}
        </Links>

        <LogoImage
          image={data.file.childImageSharp.gatsbyImageData}
          alt="Logótipo"
        />
      </Container>
      <StyledFollowUsContainer>
        <FollowUs />
      </StyledFollowUsContainer>
    </MenuContainer>
  );
};

export default Menu;

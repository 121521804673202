import React from "react";
import styled from "@emotion/styled";

const RelativeContainer = styled.div`
  color: #fff;
  min-height: 500px;
  background: ${(props) =>
    props.isRecipes
      ? `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${props.image}) 50% / cover;`
      : `url(${props.image}) 50% / cover;`};
`;

const HeaderImage = ({ children, image, isRecipes }) => (
  <RelativeContainer image={image} isRecipes={isRecipes}>
    {children}
  </RelativeContainer>
);

export default HeaderImage;

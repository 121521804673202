/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

function SEO({ title, keywords = [], image = "/logotipo.png" }) {
  const defaultKeywords = [
    "cogumelo",
    "cogumelos",
    "argoncilhe",
    "horta",
    "ecológica",
    "sustentável",
    "shitake",
    "pleurotus",
    "desidratados",
    "biológico",
    "alimentação",
    "proteína",
    "saúde",
    "vegetariano",
    "comida",
    "vegano",
    "fungo",
    "mushroom",
    "natureza",
    "santa maria da feira",
    "sem glúten",
  ];
  const groupedKeywords = defaultKeywords.concat(keywords);
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  );

  const metaDescription =
    "Brotámos em 2016 quando os nossos fundadores, Sónia Lei e Nuno Pereira, decidiram ir além do agradável paladar do cogumelo, para se especializarem na sua produção. A nossa missão é transformar a paixão pelo que fazemos num cogumelo único, 100% biológico. Para isso implementámos técnicas de produção ecológica e sustentável, assentes na valorização da matéria orgânica e que promovem a economia circular.";
  const ogImage = site.siteMetadata.siteUrl + image;

  return (
    <Helmet
      htmlAttributes={{
        lang: "pt",
      }}
      title={title}
      meta={[
        {
          name: "viewport",
          content: "width=device-width,initial-scale=1",
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: "og:image",
          content: ogImage,
        },
      ].concat(
        groupedKeywords.length > 0
          ? {
              name: `keywords`,
              content: groupedKeywords.join(`, `),
            }
          : []
      )}
    >
      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-ZMDM2JS045"
      />
      <script>
        {`
         window.dataLayer = window.dataLayer || [];
         function gtag(){dataLayer.push(arguments);}
         gtag('js', new Date());
       
         gtag('config', 'G-ZMDM2JS045');
        `}
      </script>
    </Helmet>
  );
}

export default SEO;
